import { Link } from "react-router-dom";
import { BenzeneSpace } from "../../components/BenzeneSpace";
import "./index.scss";

export const Landing = () => {
  return (
    <div className="Landing">
      <BenzeneSpace />
      <h1>精神力研究协会</h1>
      <h4>Spirituality Research Society</h4>
      <div className="buttons">
        <Link to="/novels" role="button" className="primary large">
          我已成年
        </Link>
        <a
          role="button"
          href="https://www.google.com"
          className="large"
          // style={{ opacity: 0.5 }}
        >
          离开
        </a>
      </div>
    </div>
  );
};
