import { ImgHTMLAttributes } from "react";
import logo from "../logo.svg";

export const Logo = (props: ImgHTMLAttributes<HTMLImageElement>) => (
  <img
    className="Logo"
    alt="logo"
    src={logo}
    width="16"
    height="16"
    {...props}
  />
);
