import { Route, Routes } from "react-router-dom";
import { request } from "../../utils/request";
import { useRequest } from "ahooks";
import { createContext, useContext } from "react";
import { ErrorDisplay } from "../../components/ErrorDisplay";
import { Loading } from "../../components/Loading";
import { NovelsList } from "../NovelsList";
import { NovelsArticle } from "../NovelsArticle";

import "./index.scss";

export interface NovelInfo {
  title: string;
  number: number;
  size: number;
}

const Context = createContext<NovelInfo[]>([]);

export const useNovels = () => useContext(Context);

const Internal = () => {
  const { data, error } = useRequest(async () => {
    // await new Promise((f) => setTimeout(f, 50000));
    const list = await request("https://data.c8h11no2.xyz/index.json");
    if (!(list instanceof Array))
      throw new Error(`novels.json must be an array.`);
    return list;
  });
  if (error) return <ErrorDisplay error={error} />;
  if (!data) return <Loading />;
  return (
    <Context.Provider value={data}>
      <Routes>
        <Route path="/" element={<NovelsList />}></Route>
        <Route path="/:id" element={<NovelsArticle />}></Route>
      </Routes>
    </Context.Provider>
  );
};

export const Novels = () => {
  return (
    <div className="Novels">
      <Internal />
    </div>
  );
};
