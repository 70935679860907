import { useEffect, useRef, useState } from "react";
import "./index.scss";

let recentReading = localStorage.getItem("recentReading");
const listeners = new Set<(n: typeof recentReading) => void>();

const useRecentReading = (number: number) => {
  const [value, update] = useState(recentReading);
  useEffect(() => {
    listeners.add(update);
    return () => void listeners.delete(update);
  }, [update]);
  return value === String(number);
};

export const RecentReading = ({ number }: { number: number }) => {
  const yes = useRecentReading(number);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!yes) return;
    ref.current?.scrollIntoView();
  }, [yes]);

  if (!yes) return null;

  return (
    <div className="RecentReading" ref={ref}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -1 19 10">
        <path d="M 16 0 Q 0 -2 0 8" />
        <path d="M 0 6 v -6 h 6" transform="translate(-.5, 8) rotate(-117)" />
      </svg>
      上次读到这里
    </div>
  );
};

export const useRecentReadingHolder = (number: number) => {
  useEffect(() => {
    const mark = () => {
      recentReading = String(number);
      listeners.forEach((f) => f(recentReading));
      localStorage.setItem("recentReading", recentReading);
    };
    const timer = setTimeout(mark, 5000);
    return () => void clearTimeout(timer);
  }, [number]);
};
