import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Landing } from "./pages/Landing";
import { Novels } from "./pages/Novels";

import "./index.scss";

const main = () => {
  const root = document.createElement("react-root");
  document.body.appendChild(root);
  ReactDOM.createRoot(root).render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/novels/*" element={<Novels />} />
      </Routes>
    </BrowserRouter>
  );
};

if (document.readyState === "complete") {
  main();
} else {
  window.addEventListener("load", main);
}

reportWebVitals();
