import { Logo } from "../Logo";
import "./index.scss";

export const Loading = () => {
  return (
    <div className="Loading">
      <Logo /> 精神连接中&nbsp;
    </div>
  );
};
