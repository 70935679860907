export function makeSingleFlight<F extends (...args: any[]) => Promise<any>>(
  fn: F
) {
  let cache: Record<string, Promise<any>> = {};
  return function (this: any, ...args: any[]) {
    const key = JSON.stringify(args);
    if (key in cache) return cache[key];
    cache[key] = fn.call(this, ...args);
    return cache[key];
  };
}
