import classNames from "classnames";
import { HTMLAttributes } from "react";

import "./index.scss";

export const Belt = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return <div {...rest} className={classNames("Belt", className)} />;
};
