export const request = async (url: string) => {
  const res = await fetch(url);
  if (!res.ok) throw new Error(`HTTP ${res.status}`);
  const mime = res.headers.get("Content-Type");
  if (mime && /json/.test(mime)) {
    return res.json();
  } else {
    return res.text();
  }
};
