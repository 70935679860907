import { useEffect, useRef } from "react";

type Key = Record<string, never>;
type Value = string | null | undefined;

const storage = new Map<Key, Value>([[{}, document.title]]);

const update = () => {
  let title = "";
  storage.forEach((i) => {
    if (typeof i === "string") {
      if (title) title = ` - ${title}`;
      title = i + title;
    }
  });
  document.title = title;
};

export const useTitle = (name: Value) => {
  const ref = useRef<Key>({});
  storage.set(ref.current, name);
  useEffect(update, [name]);
  useEffect(() => {
    const { current } = ref;
    return () => {
      storage.delete(current);
      update();
    };
  }, []);
};
