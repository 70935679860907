import { useRequest } from "ahooks";
import { HTMLAttributes, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { NovelInfo, useNovels } from "../Novels";
import { ErrorDisplay } from "../../components/ErrorDisplay";
import { Loading } from "../../components/Loading";
import { request } from "../../utils/request";
import { makeSingleFlight } from "../../utils/makeSingleFlight";
import { Logo } from "../../components/Logo";
import { Belt } from "../../components/Belt";
import { useTitle } from "../../utils/useTitle";

import "./index.scss";
import { useRecentReadingHolder } from "../../components/RecentReading";

const cache = new Map<number, JSX.Element[]>();

const loadContent = makeSingleFlight(async (info: NovelInfo) => {
  const res = cache.get(info.number);
  if (res) return res;
  // await new Promise((f) => setTimeout(f, 5000));
  const rawContent = await request(
    `https://data.c8h11no2.xyz/${info.number}.txt`
  );
  if (typeof rawContent != "string")
    throw new Error("content is not a string.");
  const content = rawContent
    .replace(/^\s*|\s*$/g, "")
    .split(/\n/g)
    .map((paragraph, index) => {
      return <p key={index}>{paragraph}</p>;
    });
  const result = { ...info, content };
  cache.set(info.number, content);
  return result;
});

const Article = ({ info }: { info: NovelInfo }) => {
  const { error } = useRequest(async () => loadContent(info), {
    refreshDeps: [info],
  });

  const content = cache.get(info.number);

  useRecentReadingHolder(info.number);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [content]);

  if (error) return <ErrorDisplay error={error} />;
  if (!content) return <Loading />;
  return <article>{content}</article>;
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  info: NovelInfo;
}

const Header = ({ info, ...props }: Props) => {
  const title = `#${info.number} ${info.title}`;
  useTitle(title);
  return (
    <Belt className="Header" {...props}>
      <Link to={{ pathname: "/" }}>
        <Logo />
      </Link>
      <div>{title}</div>
      <div style={{ flex: 1 }}></div>
      <Link to={{ pathname: "/novels" }}>
        <svg
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: "currentColor", display: "block" }}
          width="16"
          height="16"
        >
          <path d="M915 556H334.782c-60 0-60-90 0-90H915c60 0 60 90 0 90z m-0.377 371H334.405c-60 0-60-90 0-90h580.218c60 0 60 90 0 90z m0-741H334.405c-60 0-60-90 0-90h580.218c60 0 60 90 0 90zM128 206c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 35.346 0 64 28.654 64 64 0 35.346-28.654 64-64 64z m0 741c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 35.346 0 64 28.654 64 64 0 35.346-28.654 64-64 64z m0-371c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 35.346 0 64 28.654 64 64 0 35.346-28.654 64-64 64z"></path>
        </svg>
      </Link>
    </Belt>
  );
};

const Footer = ({ info, ...rest }: Props) => {
  const novels = useNovels();
  const { number } = info;
  const prev = novels.find((i) => +i.number === +number - 1);
  const next = novels.find((i) => +i.number === +number + 1);
  useEffect(() => {
    if (next) loadContent(next);
    if (prev) loadContent(prev);
  }, [next, prev]);
  return (
    <Belt className="Footer" {...rest}>
      {prev ? (
        <Link to={{ pathname: `/novels/${prev.number}` }}>上一章</Link>
      ) : null}
      <div style={{ flex: 1 }}></div>
      {next ? (
        <Link to={{ pathname: `/novels/${next.number}` }}>下一章</Link>
      ) : null}
    </Belt>
  );
};

export const NovelsArticle = (props: HTMLAttributes<HTMLDivElement>) => {
  const novels = useNovels();
  const { id } = useParams();
  if (!novels) return <Loading />;
  const info = novels ? novels.find((i) => String(i.number) === id) : null;
  if (!info) return <ErrorDisplay error={`id=${id} not found.`} />;
  return (
    <div className="NovelsArticle" {...props}>
      <Header info={info} />
      <Article info={info} />
      <Footer info={info} />
    </div>
  );
};
