import { useEffect, useRef } from "react";
import logo from "../../logo.svg";

import "./index.scss";

const { random, floor } = Math;

const DURATION = 4000;

const createBenzene = () => {
  const { offsetWidth, offsetHeight } = document.documentElement;
  const img = document.createElement("img");
  const M = 5;
  const x = floor((random() - 0.5) * offsetWidth * M);
  const y = floor((random() - 0.5) * offsetHeight * M);
  img.src = logo;
  img.style.setProperty("--x", x + "px");
  img.style.setProperty("--y", y + "px");
  img.style.setProperty(
    "--color",
    `rgba(255,255,255,${(random() * 2) / 3 + 0.3})`
  );
  img.addEventListener("animationend", () => img.remove());
  return img;
};

const calculateInterval = () => {
  const { offsetWidth, offsetHeight } = document.documentElement;
  return floor(60_000_000 / (offsetWidth * offsetHeight));
};

export const BenzeneSpace = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current } = ref;
    if (!current) return;
    let unmounted = false;

    // Create initialized Benzene objects synchronously.
    const interval = calculateInterval();
    for (let i = 0; i < DURATION; i += interval) {
      const div = createBenzene();
      div.style.animationDelay = -i + "ms";
      current.appendChild(div);
    }

    // Create Benzene objects at each interval.
    const nextBenzene = () => {
      if (unmounted) return;
      const div = createBenzene();
      current.appendChild(div);
      const interval = calculateInterval();
      window.setTimeout(nextBenzene, interval);
    };
    nextBenzene();

    return () => {
      unmounted = true;
    };
  }, []);
  const style: Record<string, string> = { "--duration": DURATION + "ms" };
  return <div className="BenzeneSpace" style={style} ref={ref} />;
};
